<template>
  <div class="payResult">
    <div class="resBox" v-if="payResText">
      <img
        class="resBox-icon"
        :src="payResText === '支付成功' ? finishPay : failPay"
        alt=""
      />
      <span class="resBox-text">{{ payResText }}</span>
      <result-course-info v-if="[2,3,21].includes(orderInfo.itemCatalog)" :order-info="orderInfo"></result-course-info>
      <span
        class="resBox-tip"
        v-if="qrCodeUrl"
        v-text="
          payResText === '支付成功'
            ? '请务必添加班主任微信获取课程服务'
            : '若出现支付异常请加下方小助手二维码咨询'
        "
      ></span>
      <img v-if="qrCodeUrl" referrerPolicy="no-referrer" class="resBox-qr" :src="qrCodeUrl" alt="" />
      <span v-if="wxNum" class="resBox-wx">微信号：{{ wxNum }}</span>
      <el-button
        class="resBox-btn"
        :disabled="!menuUrl"
        @click="[2,3,21].includes(orderInfo.itemCatalog)?handleCheckCourse():$router.push(menuUrl)"
        type="primary"
        >
        {{[2,3,21].includes(orderInfo.itemCatalog)?orderInfo.itemCatalog==21?"去选课":'去上课':"返回个人中心"}}</el-button
      >
    </div>
  </div>
</template>

<script>
import { connectState } from '@/utils/wyUtil';
import ResultCourseInfo from './CourseInfo.vue';

export default {
  components: { ResultCourseInfo },
  data() {
    return {
      finishPay: require('../../assets/img/finishPay.png'),
      failPay: require('../../assets/img/failPay.png'),
    };
  },
  computed: {
    ...connectState('pay', ['orderInfo', 'payResText', 'qrCodeUrl', 'wxNum', 'menuUrl']),
  },
  created() {
    if (this.$route.query.orderCode) this.getStatus(this.$route.query.orderCode);
  },
  methods: {
    getStatus(orderCode) {
      this.$store.dispatch('pay/getOrderStatus', { orderCode });
    },
    handleCheckCourse() {
      if (this.orderInfo.itemCatalog == 21) {
        this.$router.push('/checkList');
      } else {
        this.$router.push(`/courseDetail/${this.orderInfo.courseId}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.payResult {
  color: #333b50;
  .resBox {
    margin: 20px auto;
    padding: 100px 0;
    width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    height: calc(100% - 100px);
    &-text {
      font-size: 54px;
      margin: 20px 0;
    }
    &-tip {
      font-size: 26px;
    }
    &-qr {
      width: 270px;
      height: 270px;
      margin: 20px 0;
    }
    &-wx {
      font-size: 20px;
      color: #a6aabb;
    }
    &-btn {
      margin-top: 20px;
    }
  }
}
</style>
