<template>
  <div class="resultCourseInfoWrapper">
    <div v-for="item in orderList" :key="item.key" class="textList">
      <div class="label">{{ item.label }}</div>
      <div class="textContent">{{ orderInfo[item.key] }}</div>
    </div>
    <div v-if="groupList.length">
      <div class="groupTitle">QQ群信息</div>
      <my-table
        :columns="columns"
        :index-able="false"
        :table-data="groupList"
        :is-page="false"
        :handle-arr="handleArr"
        empty-text="暂无群信息"
      ></my-table>
    </div>
  </div>
</template>
<script>
import MyTable from '@/components/MyTable.vue';

export default {
  name: 'resultCourseInfo',
  components: { MyTable },
  props: {
    orderInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      orderList: [
        { label: '订单编号：', key: 'orderCode' },
        { label: '支付流水号：', key: 'thirdPayCode' },
        { label: '商品名称：', key: 'itemName' },
      ],
      columns: [
        { prop: 'name', label: '群名称' },
        { prop: 'number', label: '群号码' },
        { prop: 'word', label: '群口令', width: 110 },
      ],
      handleArr: [
        { text: '复制', style: 'color:#3377FF;', action: (row) => this.handleCopy(row.number) },
      ],
      tableData: [],
    };
  },
  computed: {
    groupList() {
      const { groupNumber } = this.orderInfo || {};
      const canParse = typeof groupNumber === 'string' && groupNumber;
      const obj = canParse ? JSON.parse(groupNumber) || {} : {};
      const { group, word } = obj;
      return Array.isArray(group)
        ? group.map((item) => ({
          name: item.name,
          number: item.number,
          word,
        })) : [];
    },
  },
  methods:{
    handleCopy(val) {
      const input = document.createElement('input');
      input.setAttribute('readonly', 'readonly');
      input.setAttribute('type', 'text');
      input.setAttribute('value', val);
      document.body.appendChild(input);
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        console.log('复制成功');
        this.$message({ message: '复制成功', type: 'success' });
      }
      document.body.removeChild(input);
    },
  }

};
</script>
<style lang="scss" >
  .resultCourseInfoWrapper{
    .el-table{
      min-height: auto !important;
      border-radius: 3px;
      border: 1px solid #CACDD6;
    }
    .has-gutter{
      border-bottom: 1px solid #CACDD6;
    }
    .el-table th{
      height: 44px;
      background: #F8F8F8;
    }
    .el-button .el-button--text{
      font-size:13px;
    }
    .el-table .cell{ font-size: 13px !important;}
    margin-bottom: 50px;
    .textList {
      width: 400px;
      padding: 0 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      color: #4e596f;
      line-height: 28px;

      .label {
        flex: none;
        width: 100px;
      }
      .textContent {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: right;
        .copyBtn {
          margin-left: 10px;
          color: #3377ff;
        }
      }
    }
    .groupTitle {
      margin-top:30px;
      padding: 0 15px;
      font-size: 16px;
      font-weight: bold;
      margin-bottom:20px;
    }

  }

</style>
